<template>
  <section>
    <e-filters
      :searching="fetching"
      @search="filterData"
      @reset="resetFiltersLocal"
    >
      <div>
        <b-row>
          <b-col md="2">
            <FormulateInput
              id="competence"
              v-model="filters.competenceDate"
              v-mask="['##-####']"
              :instruction="$t('Digite o mês e ano. Ex: 02-2022')"
              :label="$t('Competência')"
              :placeholder="$t('mm-aaaa')"
            />
          </b-col>
        </b-row>
      </div>
    </e-filters>

    <b-card-actions
      action-refresh
      :show-loading="fetching"
      @refresh="getData"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>

      <b-table
        id="order-table"
        show-empty
        responsive
        striped
        class="bordered"
        :empty-text="getEmptyTableMessage($tc('TESTE'))"
        :fields="fields"
        :items="accountPayableCloses"
        no-local-sorting
        :sort-by.sync="sorting.sortBy"
        :sort-desc.sync="sorting.sortDesc"
        @sort-changed="getData"
      >
        <!-- <template #cell(action)="row">
          <e-grid-actions
            :show-update="row.item.id"
            :show-delete="false"
          />
        </template> -->
      </b-table>
      <b-row class="pt-1">
        <b-col>
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col>
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="order-table"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <fab
      main-icon="event_available"
      :main-tooltip="$t('Fechar mês anterior')"
      :busy="fetching"
      @click="onCloseAccountPayablePeriod"
    />
  </section>
</template>

<script>
import { BRow, BCol, BPagination, BTable } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { EPageSizeSelector, EPaginationSummary, EFilters } from '@/views/components'
import fab from '@/views/components/FAB.vue'
import { statusTypes } from '@/mixins'
import { mapActions, mapState } from 'vuex'
import moment from 'moment'

export default {
  components: {
    BRow,
    BCol,
    BCardActions,
    BPagination,
    BTable,
    EPaginationSummary,
    EPageSizeSelector,
    fab,
    EFilters,
  },

  mixins: [statusTypes],

  data() {
    return {
      fetching: false,
      retryErrorMessage: '',
    }
  },

  computed: {
    ...mapState('pages/settings/periodClose/accountsPayableCloses', [
      'accountPayableCloses',
      'paging',
      'sorting',
      'filters',
    ]),

    getLastMonthStr() {
      return moment().subtract(1, 'month').format('MM-yyyy')
    },

    fields() {
      return [
        // {
        //   label: this.$t('Ações'),
        //   key: 'action',
        //   thClass: 'text-center',
        //   tdClass: 'text-center',
        //   thStyle: { width: '100px' },
        // },
        {
          label: this.$t('Competência'),
          key: 'competenceDate',
          thStyle: { width: '140px' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: this.$t('Data do fechamento'),
          key: 'closedDate',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '140px' },
          formatter: val => this.$options.filters.date(val, '-'),
          sortable: true,
        },
        {
          label: this.$t('Responsável'),
          key: 'closedBy.name',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: true,
        },
      ]
    },
  },

  mounted() {
    this.getData()
  },

  methods: {
    ...mapActions('pages/settings/periodClose/accountsPayableCloses', [
      'fetchAccountPayableCloses',
      'closePeriod',
      'resetFilters',
      'setCurrentPage',
      'setPageSize',
    ]),

    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          await this.fetchAccountPayableCloses()
        } catch (error) {
          this.showGenericError({ error })
        } finally {
          this.fetching = false
        }
      })
    },

    filterData() {
      this.setCurrentPage(1)
      this.getData()
    },
    resetFiltersLocal() {
      this.resetFilters()
      this.setCurrentPage(1)
      this.getData()
    },
    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },
    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },

    async onCloseAccountPayablePeriod() {
      const confirmed = await this.confirm({
        title: this.$t(`Fechar competência ${this.getLastMonthStr}?`),
        text: `Esta ação não poderá ser desfeita e as ações de "Baixa individual", "Baixa em lote", "Edição" e "Estorno" de Contas a pagar serão bloqueadas para registros criados na competência de ${this.getLastMonthStr}. \n Tem certeza que confirma esta ação?`,
        focusCancel: true,
      })

      if (!confirmed) return

      try {
        this.fetching = true
        await this.closePeriod({})
        this.showSuccess({})

        await this.getData()
      } catch (error) {
        if (error.response?.data?.retry) {
          const errors = error.response?.data?.retry || []
          if (errors) {
            // eslint-disable-next-line prefer-destructuring
            this.retryErrorMessage = errors[0]
          }

          try {
            await this.confirmRevision()
          } catch (err) {
            this.showError({ error: err })
          }
        } else {
          this.showError({ error })
        }
      } finally {
        this.fetching = false
      }
    },

    async confirmRevision() {
      const confirmed = await this.confirm({
        title: this.$t(this.retryErrorMessage || 'Aviso', { competenceDate: this.getLastMonthStr }),
        text: `Depois não será possível desfazer o fechamento. Tem certeza que confirma esta ação?`,
        confirmButtonText: this.$t('Confirmo o fechamento'),
        cancelButtonText: this.$t('Cancelar'),
        focusCancel: true,
      })

      if (confirmed) {
        await this.closePeriod({ forced: true })
        this.showSuccess({})
        await this.getData()
      }
    },
  },
}
</script>
